define("frontend/controllers/change-password", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    password: null,
    confirmPassword: null,
    passwordError: false,
    confirmError: false,
    currentUser: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    resetPassword: computed.alias('currentUser.user.has_temporary_password'),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return !this.get('currentUser.user.company.id');
    }),
    avatarLogo: computed('currentUser.user.avatar_url', function () {
      var logo = this.get('currentUser.user.avatar_url');

      if (logo) {
        return logo;
      } else {
        return 'assets/images/staff/placeholder-human-image@3x.png';
      }
    }),
    companyLogo: computed('isPetParent', 'currentUser.user.company.logo_url', 'currentUser.user.client_accounts.[]', function () {
      var logo = null;

      if (this.get('isPetParent')) {
        var _this$get$sort = this.get('currentUser.user.client_accounts').sort(function (a, b) {
          return a.created_at < b.created_at ? 1 : -1;
        }),
            _this$get$sort2 = _slicedToArray(_this$get$sort, 1),
            newestCompany = _this$get$sort2[0];

        if (newestCompany) {
          logo = newestCompany.company_logo;
        }
      } else {
        logo = this.get('currentUser.user.company.logo_url');
      }

      if (logo) {
        return logo;
      } else {
        return '/assets/images/settings/company-placeholder.png';
      }
    }),
    companyName: computed('isPetParent', 'currentUser.user.client_accounts.[]', function () {
      if (this.get('isPetParent')) {
        var _this$get$sort3 = this.get('currentUser.user.client_accounts').sort(function (a, b) {
          return a.created_at < b.created_at ? 1 : -1;
        }),
            _this$get$sort4 = _slicedToArray(_this$get$sort3, 1),
            newestCompany = _this$get$sort4[0];

        if (newestCompany) {
          return newestCompany.company_name;
        }
      }

      return null;
    }),
    actions: {
      savePassword: function savePassword() {
        var _this = this;

        var pass = this.get('password');
        var confirm = this.get('confirmPassword');
        this.set('passwordError', false);
        this.set('confirmError', false);

        if (!pass || pass.length < 8) {
          this.set('passwordError', true);
        } else if (pass != confirm) {
          this.set('confirmError', true);
        } else {
          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/users/").concat(this.get('currentUser.user.id'), "/update_password"),
            method: 'POST',
            data: {
              password: pass
            }
          }).done(function (response) {
            // This is the only way to get the redirect to pull the new currentUser
            var store = _this.get('session.store');

            var cookieData = {
              authenticated: {
                authenticator: 'authenticator:jwt',
                token: _this.get('session.data.authenticated.token'),
                refresh_token: _this.get('session.data.authenticated.refresh_token'),
                user_email: _this.get('session.data.authenticated.tokenData.user_email'),
                exp: _this.get('session.data.authenticated.tokenData.exp'),
                tokenData: _this.get('session.data.authenticated.tokenData')
              }
            };
            store.clear().then(function () {
              store.persist(cookieData).then(function () {
                if (_this.get('resetPassword')) {
                  _this.transitionToRoute('/');
                } else if (_this.get('isPetParent')) {
                  if (response.is_primary_contact) {
                    _this.transitionToRoute('add-client.primary-contact', {
                      queryParams: {
                        householdId: _this.get('currentUser.user.household.id')
                      }
                    });
                  } else {
                    _this.transitionToRoute('add-client.secondary-contact', _this.get('currentUser.user.household.id'));
                  }
                } else {
                  _this.transitionToRoute('profile.edit-about-me');
                }
              });
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});